/*-----------------------------------------------
|   Showcase
-----------------------------------------------*/
.devices-wrapper{
  transform: translateY(-50%);
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: 40%;
  width: 56.25rem;
  height: 56.25rem;

  .device{
    width: 100% !important;
    border-radius: 0.5rem;
    border: 0.25rem solid rgba($gray-400, 0.1);
    box-shadow: 0.125rem 0.125rem 1.25rem 0 rgba($black, 0.3);
    width: 6.25rem;
    transition: transform 2s cubic-bezier(.77,0,.18,1);
  }

  [class*="ipad--l-"]{
    width: 50%;
    position: absolute;
  }
  .ipad--l-2{
    left: 25%;
    top: 35%;
  }
  [class*="scrolling-marquee"]{
    width: 60%;
    position: relative;
    color: white;
    font-size: 16px;
  }
  .scrolling-marquee-1{
    left: 20%;
    top: 55%;
  }

}
